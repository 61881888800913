import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import "../styles/global.css";

export const ProjectPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <h2
        className="has-text-weight-bold is-size-1"
        style={{
          backgroundColor: "#212529",
          color: "#212529",
          padding: "1rem"
        }}
      >
        Project
      </h2>
      <div className="container content" style={{ padding: "4rem 0" }}>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h2>
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  );
};

ProjectPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const ProjectPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ProjectPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Project">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.projectdescription}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

ProjectPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ProjectPost;

export const pageQuery = graphql`
  query ProjectPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        projectdescription
        tags
      }
    }
  }
`;
